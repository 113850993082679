import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { getDownloadURL } from '@angular/fire/storage';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import { FileUpload, Project, Receipt, getCountryClasses } from '@shared/models';
import { AccountingClassifierService } from '../../../services/accounting-classifier.service';
import { AuthService } from '../../../services/auth.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { ReceiptService } from '../../../services/receipt.service';
import { currencies } from '../../../data/currencies';

@Component({
  selector: 'app-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.css'],
})
export class ReceiptDetailsComponent implements OnInit {
  @Input("receipt") receipt: Partial<Receipt> | undefined;
  @Input("projects") projects: Project[] | [];

  public form: any;

  public file: any;
  public category: string = "";
  public description: string = "";

  public uploadPercentage: any;
  public processingLoading: boolean = false;

  public selectedFiles?: FileList;
  private currentFileUpload?: FileUpload;
  public downloadURL: string;
  public user_profile: any;
  public canUseService: boolean = false;

  public isLoading: boolean = true;

  private isLoadingProjects: boolean = true;

  public accountingClassifiers: any[];

  public vatClasses: any[] = [];

  public currencyList: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private accountingClassifierService: AccountingClassifierService,
    private loadingController: LoadingController,
    private uploadService: FileUploadService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private toastController: ToastController,
    private receiptService: ReceiptService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      issuer_name: ['', [Validators.required]],
      amount: ['', Validators.required],
      project: [''],
      currency: [''],
      date: ['', Validators.required],
      account: ['', Validators.required],
      description: ['', Validators.required],
      vat_class: ['', Validators.required],
    });

    this.form.get('date')?.setValue(new Date().toISOString());
    this.user_profile = this.authService.getUserProfileStatic();

    const currentLanguage = 'fi';

    this.currencyList = currencies;

    this.accountingClassifierService.getAll().then((data: any[]) => {
      this.accountingClassifiers = data.map(d => ({
        name: d.names?.[currentLanguage] ?? '', // Use the currentLanguage to dynamically select the name
        number: d.number
      }));
    });

    this.vatClasses = getCountryClasses('fi');
  }

  checkIfUserCanUse() {

    if (this.user_profile.subscriber_type === 'free') {
      this.canUseService = true;
    }
    else {
      if (this.projects.length > 0) {
        this.canUseService = true;
      }
      else {
        this.canUseService = false;
      }
    }

    if (this.isLoadingProjects === false) {
      this.isLoading = false;
    }
    else {
      this.isLoading = true;
    }
  }

  ionViewDidEnter() {

    if (this.receipt === undefined) {
      this.receipt = { files: [], project: null };
    }
    else {
      this.form.get('date')?.setValue(this.receipt.date);
      this.form.get('project')?.setValue(this.receipt.project);
      this.form.get('amount')?.setValue(this.receipt.amount);
      this.form.get('state')?.setValue(this.receipt.state);
      this.form.get('vat_class')?.setValue(this.receipt.vat_class);
      this.form.get('description')?.setValue(this.receipt.description);
      this.form.get('account')?.setValue(this.receipt.account);
      this.form.get('issuer_name')?.setValue(this.receipt.issuer_name);
      this.form.get('currency')?.setValue(this.receipt.currency);
    }
  }

  dismiss(cancel: boolean) {

    this.modalCtrl.dismiss({
      cancel: cancel,
      receipt: this.receipt
    });
  }

  async deletePhoto(index: number) {
    this.receipt.files.splice(index, 1);
  }

  async save() {
    const user_profile = this.authService.getUserProfileStatic();

    const modifiedReceipt = this.form.value as Partial<Receipt>;

    modifiedReceipt.files = this.receipt.files;
    modifiedReceipt.user_id = user_profile.user_id;
    modifiedReceipt.date = modifiedReceipt.date;
    modifiedReceipt.state = 'CREATED';
    modifiedReceipt.report_id = "";

    if (this.receipt.id) {
      await this.receiptService.update(user_profile.subscriber_id, this.receipt.id, modifiedReceipt as Receipt);
    }
    else {
      await this.receiptService.add(user_profile.subscriber_id, modifiedReceipt);
    }

    const receiptId = this.receipt.id;
    this.receipt = modifiedReceipt;
    this.receipt.id = receiptId;

    this.showToast("Kuitti tallennettu onnistuneesti");
    this.dismiss(false);
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const rawFile: File | null = this.selectedFiles.item(0);

      if (rawFile) {
        // Validate file name and type upfront
        if (!this.isValidFile(rawFile)) {
          this.showToast("Vain PNG tai JPEG tiedostot ovat sallittuja (max 5 MB).");
          this.selectedFiles = undefined;
          return;
        }

        // Trigger upload
        this.upload();
      }
    }
  }

  private isValidFile(file: File): boolean {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/heic'];
    const maxSize = 5 * 1024 * 1024; // 5 MB limit

    // Validate file type and size
    if (!allowedTypes.includes(file.type) || file.size > maxSize) {
      return false;
    }

    // Validate file name: Ensure no illegal characters
    if (file.name.includes('(') || file.name.includes(')')) {
      return false;
    }

    return true;
  }

  async convertIfHeic(file: File): Promise<File> {
    console.log('Checking for HEIC file:', file);
    if (/\.hei(c|f)+$/.test(file.name.toLowerCase())) {
      try {
        console.log('Converting HEIC to JPEG...');
        const blob = await heic2any({ blob: file, toType: "image/jpeg", quality: 0.8 }) as Blob;
        const fileName = file.name.replace(/\.[^/.]+$/, ".jpg");
        return this.blobToFile(blob, fileName);
      } catch (error) {
        console.error('HEIC conversion error:', error);
        this.showToast('Virhe HEIC-tiedoston muuntamisessa.');
        throw error;
      }
    }
    return file;
  }


  async upload() {
    console.log("Upload triggered");
    if (this.selectedFiles) {
      const rawFile: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      const loading = await this.loadingController.create({
        message: 'Ladataan',
      });

      if (rawFile.name.indexOf('(') > 0) {
        this.showToast("Vääriä merkkejä tiedoston nimessä");
        return;
      }
      loading.present();

      const user_profile = this.authService.getUserProfileStatic();

      if (rawFile) {

        const file = await this.convertIfHeic(rawFile);

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        }
        try {
          const compressedFile: any = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          this.processingLoading = true;
          this.currentFileUpload = new FileUpload(compressedFile);
          const uploadTask = this.uploadService.pushFileToStorage(user_profile.subscriber_id, this.currentFileUpload);
          uploadTask.on('state_changed',
            (snapshot: { bytesTransferred: number; totalBytes: number; state: any; }) => {
              this.uploadPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + this.uploadPercentage + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error: any) => {
              this.processingLoading = false;
              loading.dismiss();
            },
            () => {

              return getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
                this.processingLoading = false;
                console.log(downloadURL);
                this.receipt.files.push(downloadURL);
                this.changeDetectorRef.detectChanges();
                loading.dismiss();
              });
            }
          );
        }
        catch (error) {
          console.log(error);
        }
      }
    }
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    const options: FilePropertyBag = { type: theBlob.type };
    return new File([theBlob], fileName, options);
  }

  compareWith(o1, o2) {
    return o1 === o2;
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1500,
      position: 'bottom'
    });
    await toast.present();
  }


}


