import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, query, orderBy, where } from '@angular/fire/firestore';
import { Invoice } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class InvoiceService {
    constructor(private firestore: Firestore) { }

    async add(subscriberId: string, invoice: Invoice): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/invoices`), Object.assign({}, invoice)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, invoiceId: string, invoice: Invoice): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/invoices/${invoiceId}`), { ...invoice });
    }

    delete(subscriberId: string, invoiceId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/invoices/${invoiceId}`));
    }

    getAllLive(subscriberId: string): Observable<Invoice[]> {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/invoices`), { idField: 'id' }) as Observable<Invoice[]>;
    }

    async getAll(subscriberId: string): Promise<Invoice[]> {
        const q = query(
            collection(this.firestore, `subscribers/${subscriberId}/invoices`),
            orderBy('date', 'asc')
        );

        const data = await getDocs(q);

        const replySet: Invoice[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Invoice(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getInvoicesByDateRange(subscriberId: string, startDate: string, endDate: string): Promise<Invoice[]> {
        const q = query(
            collection(this.firestore, `subscribers/${subscriberId}/invoices`),
            where('invoice_date', '>=', startDate),
            where('invoice_date', '<=', endDate),
            orderBy('invoice_date', 'asc')
        );

        const data = await getDocs(q);

        const replySet: Invoice[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Invoice(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}
