"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Receipt = void 0;
var Receipt = /** @class */ (function () {
    function Receipt() {
        this.id = "";
        this.user_id = "";
        this.project = "";
        this.description = "";
        this.date = "";
        this.report_id = "";
        this.project = "";
        this.issuer_name = "";
        this.issuer_id = "";
        this.account = "";
        this.claim_id = "";
        this.state = "";
        this.amount = 0;
        this.private = false;
        this.files = [];
        this.vat_class = "";
        this.currency = "";
        this.approval_description = "";
        this.approved_by_project_manager = "";
        this.approved_by_supervisor = "";
        this.approved_by_project_manager_at = "";
        this.approved_by_supervisor_at = "";
    }
    return Receipt;
}());
exports.Receipt = Receipt;
