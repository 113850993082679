"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceRow = exports.Invoice = void 0;
var Invoice = /** @class */ (function () {
    function Invoice() {
        this.date = '';
        this.customer_id = '';
        this.due_date = '';
        this.paid_date = '';
        this.state = "draft";
        this.reference_id = "";
        this.creator = "";
        this.created_date = '';
        this.type = "sales";
        this.gross_amount = 0;
        this.net_amount = 0;
        this.notes = "";
        this.invoice_rows = [];
        this.receipt = [];
    }
    return Invoice;
}());
exports.Invoice = Invoice;
var InvoiceRow = /** @class */ (function () {
    function InvoiceRow() {
        this.description = "";
        this.quantity = 0;
        this.unit_price = 0;
        this.vat_percentage = 0;
        this.total = 0;
    }
    return InvoiceRow;
}());
exports.InvoiceRow = InvoiceRow;
