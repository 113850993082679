export const environment = {
  firebase: {
    apiKey: "AIzaSyBTXAF2VA7dFXF8ePRk400QvRRvxcJ4vfc",
    authDomain: "proveit-prod.firebaseapp.com",
    projectId: "proveit-prod",
    storageBucket: "proveit-prod.appspot.com",
    messagingSenderId: "796614621815",
    appId: "1:796614621815:web:4124733ad8138d99f1e472",
    measurementId: "G-RZDQ37B7D0"
  },
  algolia: {
    appId: '41A6Q1L202',
    apiKey: '911a283d76836885aedb41f5138030a1'
  },
  sse_server: 'https://proveit-sse-server-796614621815.europe-north1.run.app',
  production: true,
  buildEnv: 'PROD',
  firebase_hosting: true,
};
