<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-img src="/assets/logo_slim.png" class="background-header"></ion-img>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <h3 class="ion-padding-start">Kuitit & Kulut</h3>

  <ion-list class="list">

    <!-- No Receipts Card -->
    <ion-card *ngIf="(!receipts || receipts.length === 0) && loading === false" class="card-item-content ion-padding">
      <h4>Ei kuitteja</h4>
      <p>Sinulle ei löytynyt vielä kuitteja, tai kaikki kuittisi ovat hyväksyttyjä. Voit lisätä uuden
        manuaalisesti ylävalikon "Lisää uusi"
        painikkeella.</p>
    </ion-card>

    <!-- Receipt Items -->
    <ion-item-sliding #item *ngFor="let receipt of receipts">
      <ion-item-options side="start">
        <ion-button color="danger" expand="full" (click)="remove(receipt?.id)">Poista</ion-button>
      </ion-item-options>
      <ion-item-options side="end">
        <ion-button expand="full" (click)="edit(receipt)">Muokkaa</ion-button>
      </ion-item-options>
      <ion-item>
        <ion-card class="card-item-content">
          <h4>{{ receipt.issuer_name }}</h4>
          <div>
            <ion-icon name="time-outline"></ion-icon>
            {{ receipt.date | date:'dd.MM.yyyy' }}
          </div>

          <div>
            <ion-icon name="card-outline"></ion-icon>
            {{ receipt.amount | number:'1.2-2' }} <span
              *ngIf="!receipt.currency || receipt.currency === 'EUR'">€</span><span
              *ngIf="receipt.currency || receipt.currency !== 'EUR'">{{receipt.currency}}</span>
          </div>

          <div>
            <ion-icon name="create-outline"></ion-icon>
            <span *ngIf="receipt.project">
              {{ getProjectName(receipt.project) }}
            </span>
            <span *ngIf="receipt.project && receipt.description"> : </span> {{ receipt.description }}
          </div>

        </ion-card>
      </ion-item>
    </ion-item-sliding>

    <!-- Load More Button -->
    <ion-item *ngIf="(receipts || receipts?.length > 0) && loading === false && more">
      <ion-button class="load-more-button" (click)="loadMore()">Lataa lisää...</ion-button>
    </ion-item>
  </ion-list>

  <!-- FAB (Floating Action Button) for Adding Tasks -->
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="addNew()">
      <ion-icon name="add" class="fab"></ion-icon>
    </ion-fab-button>
  </ion-fab>

</ion-content>