import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ENVIRONMENT } from '../tokens/app.tokens';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    private sseUrl = '';
    private eventSource: EventSource | null = null;
    private reconnectDelay = 1000; // Initial delay in ms
    private maxReconnectDelay = 30000; // Max delay in ms
    private isReconnecting = false;

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: any
    ) {
        this.sseUrl = `${environment.sse_server}/events`;
    }

    async getServerSentEvents(): Promise<Observable<any>> {
        const userProfile = this.authService.getUserProfileStatic();
        if (!userProfile) {
            throw new Error('User is not authenticated or profile is missing.');
        }

        return new Observable((observer) => {
            const connect = async () => {
                const token = await this.getFreshToken();
                const urlWithToken = `${this.sseUrl}?auth=${encodeURIComponent(token)}`;
                console.log('Connecting to SSE with URL:', urlWithToken);

                // Clear any existing connection
                if (this.eventSource) {
                    console.log('Clearing existing SSE connection.');
                    this.eventSource.close();
                    this.eventSource = null;
                }

                // Create a new connection
                this.eventSource = new EventSource(urlWithToken);

                this.eventSource.onmessage = (event) => {
                    try {
                        const data = JSON.parse(event.data);
                        console.log('SSE Data received:', data);
                        observer.next(data);
                        this.resetReconnectDelay(); // Successful message, reset delay
                    } catch (err) {
                        console.error('Error parsing SSE data:', err, event.data);
                        observer.error(err);
                    }
                };

                this.eventSource.onerror = async (error: any) => {
                    console.error('SSE connection error:', error);

                    // Check for 401 errors
                    if (error?.status === 401) {
                        console.warn('SSE received 401 error, refreshing token...');
                        try {
                            await this.getFreshToken();
                        } catch (tokenError) {
                            console.error('Error refreshing token:', tokenError);
                            observer.error(tokenError);
                            return;
                        }
                    }

                    observer.error(error);

                    // Close the current connection and attempt reconnect
                    if (this.eventSource) {
                        this.eventSource.close();
                        this.eventSource = null;
                    }

                    this.scheduleReconnect(connect);
                };

                this.eventSource.onopen = () => {
                    console.log('SSE connection established.');
                };
            };

            // Start the connection
            connect();

            // Cleanup when the subscription ends
            return () => {
                console.log('Closing SSE connection.');
                if (this.eventSource) {
                    this.eventSource.close();
                    this.eventSource = null;
                }
            };
        });
    }

    private async getFreshToken(): Promise<string> {
        const user = await this.authService.getUser();
        if (user) {
            const token = await user.stsTokenManager.accessToken;
            console.log('Token refreshed successfully.');
            return token;
        } else {
            throw new Error('User is not authenticated or profile is missing.');
        }
    }

    private scheduleReconnect(connect: () => void) {
        if (this.isReconnecting) {
            return;
        }
        this.isReconnecting = true;

        const delay = this.reconnectDelay;
        console.log(`Reconnecting in ${delay / 1000} seconds...`);

        setTimeout(() => {
            this.isReconnecting = false;
            this.reconnectDelay = Math.min(
                this.reconnectDelay * 2,
                this.maxReconnectDelay
            );
            connect();
        }, delay);
    }

    private resetReconnectDelay() {
        this.reconnectDelay = 1000; // Reset to initial delay
    }
}
