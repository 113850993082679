export const currencies = [
    { id: 'EUR', name: "EUR" },
    { id: 'SEK', name: "Ruotsin kruunu" },
    { id: 'NOK', name: "Norjan kruunu" },
    { id: 'DKK', name: "Tanskan kruunu" },
    { id: 'PLN', name: "Puolan zloty" },
    { id: 'USD', name: "Yhdysvaltain Dollari" }
];

export const getCurrencyText = (id: string): string => {
    const a = currencies.find(a => a.id === id);
    return a.name;
}